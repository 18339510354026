import exp from "constants";

export const AUTH = "/auth";
export const HOME = "/";
export const RENT = "/rent";
export const BUY = "/buy";
export const HOME_TOURS = "/home-tours";
export const SELECT_YOUR_SPACE = "/select-your-space";
export const SHOP = "/shop";
export const CONTACT_US = "/contact-us";
export const REALTORS = "/realtors";
export const PROPERTY_MANAGERS = "/property-managers";
export const REQUEST_TOUR = "/request-tour";
export const PARTNERSHIPS = "/partnerships";
export const TERMS_AND_CONDITIONS = "/terms-and-conditions";

export const QUESTIONNAIRE_BUY = `${BUY}/questionnaire`;
export const QUESTIONNAIRE_BUY_RESULTS = `/questionnaire-results`;
export const QUESTIONNAIRE_BUY_RESULTS_PREVIEW = `/questionnaire-results-preview`;
export const QUESTIONNAIRE_RENT = `${RENT}/questionnaire`;
export const QUESTIONNAIRE_RENT_RESULTS = `/questionnaire-results`;
export const QUESTIONNAIRE_RENT_RESULTS_PREVIEW = `/questionnaire-results-preview`;

export const QUESTIONNAIRE_RENTAL = `${RENT}/questionnaire`;
