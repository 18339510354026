import React from "react";
import { Provider } from "react-redux";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { Authenticator } from "@aws-amplify/ui-react";

import { AppHelmet } from "../AppHelmet";

import { theme } from "../../config/theme";
import { store } from "../../store/store";

interface AppProviderProps {
  children: React.ReactNode;
}

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  return (
    <Authenticator.Provider>
      <Provider store={store}>
        <AppHelmet />
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </Provider>
    </Authenticator.Provider>
  );
};
