import React from "react";
import { Box } from "@mui/material";

import { Header } from "./Header";
import { Footer } from "./Footer";

interface UiShellProps {
  children: React.ReactNode;
}

export const UiShell: React.FC<UiShellProps> = ({ children }) => {
  return (
    <>
      <Header />
      <Box component="main" flexGrow={1}>
        <Box pt={`92px`}>{children}</Box>
      </Box>
      <Footer />
    </>
  );
};
