import React from "react";
import { Box, useTheme } from "@mui/material";

interface SegmentBgImageProps {
  imgSrc: string;
  height: number;
  overlayColor?: string;
  overlayOpacity?: number;
  children?: React.ReactNode;
  gradientFade?: boolean;
}

export const SegmentBgImage: React.FC<SegmentBgImageProps> = ({
  imgSrc,
  height,
  overlayColor = "black",
  overlayOpacity = 0.75,
  children,
  gradientFade = false,
}) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: "relative",
        minHeight: `${height}px`,
        width: "100%",
        overflow: "hidden",
      }}
    >
      {/* Background Image */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `url(${imgSrc})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      />

      {/* Overlay */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: overlayColor,
          opacity: overlayOpacity,
          zIndex: 1, // Ensures overlay is above the image
        }}
      />
      {/* gradient fade */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background:
            gradientFade &&
            `linear-gradient(0deg, rgba(0,0,0,1) 25%, rgba(0,0,0,0) 100%);`,
          opacity: 1,
          zIndex: 2, // Ensures overlay is above the image
        }}
      />

      {/* Content */}
      <Box
        className="content"
        sx={{
          position: "relative",
          zIndex: 2, // Ensures content is above the overlay
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
