import React, { useState } from "react";
import { navigate } from "gatsby";
import { useDispatch } from "react-redux";
import {
  Container,
  Box,
  Button,
  AppBar,
  ButtonBase,
  useTheme,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useAuthenticator } from "@aws-amplify/ui-react";

import { Logo } from "../../Logo";
import { AlignVertCenter } from "../../AlignVertCenter";
import { navigation } from "./Header.content";
import { paleColor } from "../../../config/theme/colors";
import {
  resetBuyQuestionnaire,
  resetRentQuestionnaire,
} from "../../../store/reducers/questionnaire.reducer";

import { HOME } from "../../../constants/routes";

export const Header: React.FC = () => {
  const { signOut, authStatus } = useAuthenticator((context) => [
    context.signOut,
    context.authStatus,
  ]);
  const theme = useTheme();
  const dispatch = useDispatch();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const appBarHeight = 66;

  const handleClickNavigate = (route: string) => {
    // Reset the questionnaires
    dispatch(resetBuyQuestionnaire());
    dispatch(resetRentQuestionnaire());

    navigate(route);
    setDrawerOpen(false);
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleClickSignOut = () => {
    signOut();
    setDrawerOpen(false);
    navigate(HOME);
  };

  const renderDrawer = () => (
    <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
      <List>
        {navigation.map((item) => (
          <ListItem key={item.id} disablePadding>
            <ListItemButton onClick={() => handleClickNavigate(item.route)}>
              <ListItemText primary={item.title} />
            </ListItemButton>
          </ListItem>
        ))}
        {authStatus === "authenticated" && (
          <ListItem disablePadding>
            <ListItemButton onClick={handleClickSignOut}>
              <ListItemText primary={`Sign Out`} />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </Drawer>
  );

  return (
    <>
      <AppBar elevation={0} sx={{ background: paleColor }}>
        <Container>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex">
              <AlignVertCenter height={appBarHeight}>
                <ButtonBase onClick={() => handleClickNavigate("/")}>
                  <Logo />
                </ButtonBase>
              </AlignVertCenter>
            </Box>

            {isSmall ? (
              <IconButton
                color="inherit"
                edge="end"
                onClick={handleDrawerToggle}
              >
                <MenuIcon color="primary" />
              </IconButton>
            ) : (
              <Box display="flex">
                <AlignVertCenter height={appBarHeight}>
                  <Box display="flex">
                    {navigation.map((item) => (
                      <Box ml={2} key={item.id}>
                        <Button
                          color="primary"
                          onClick={() => handleClickNavigate(item.route)}
                          sx={{ color: !isSmall && theme.palette.primary.main }}
                        >
                          {item.title}
                        </Button>
                      </Box>
                    ))}
                  </Box>
                </AlignVertCenter>
              </Box>
            )}
          </Box>
        </Container>
      </AppBar>
      {isSmall && renderDrawer()}
    </>
  );
};
