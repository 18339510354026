export const buyAnswers = {
  monthly_budget: "",
  location_type: [],
  lifestyle: {
    Walkability: 3,
    "Stores/Restaurants": 3,
    "Road Appeal": 3,
    "Public Schools": 3,
    "Parks/Nature": 3,
    "Near Lakes": 3,
    "Near Coast": 3,
    Livibility: 3,
    Commute: 3,
    "Community Vibrancy": 3,
  },
  property_type: "",
  bedrooms: "Any",
  bathrooms: "Any",
  property_features: [],
};

export const rentAnswers = {
  monthly_budget: "",
  location_type: [],
  lifestyle: {
    Walkability: 3,
    "Stores/Restaurants": 3,
    "Parks/Nature": 3,
    "Public Schools": 3,
    "Near Coast": 3,
    "Near Lakes": 3,
    "Road Appeal": 3,
    Commute: 3,
    "Community Vibrancy": 3,
    Livibility: 3,
  },
  property_type: "",
  bedrooms: "Any",
  bathrooms: "Any",
  property_features: [],
};
