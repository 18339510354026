import { uid } from "uid";
import {
  HOME,
  RENT,
  BUY,
  HOME_TOURS,
  SHOP,
  CONTACT_US,
} from "../../../constants/routes";

export const navigation = [
  { id: uid(), title: "Home", route: HOME },
  // { id: uid(), title: "Rent", route: RENT },
  // { id: uid(), title: "Buy", route: BUY },
  { id: uid(), title: "Home Tours", route: HOME_TOURS },
  // { id: uid(), title: "Shop", route: SHOP },
  { id: uid(), title: "Contact Us", route: CONTACT_US },
];
