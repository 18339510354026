import React from "react";
import { AppProvider } from "./src/components/AppProvider";

interface Props {
  element: React.ReactNode;
}

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }: Props) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  return <AppProvider>{element}</AppProvider>;
};
