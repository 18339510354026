import React from "react";
import { navigate } from "gatsby";
import { Box, ButtonBase, useMediaQuery, useTheme } from "@mui/material";

import LogoImg from "../../images/logo.png";
import LogoIcon from "../../images/logo.png";

interface LogoProps {
  icon?: boolean;
}

export const Logo: React.FC<LogoProps> = ({ icon }) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <ButtonBase onClick={() => navigate("/")}>
      <Box
        component="img"
        src={icon ? LogoIcon : LogoImg}
        width={isSmall ? `250px` : `375px`}
        alt="logo"
      />
    </ButtonBase>
  );
};
