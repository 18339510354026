import React from "react";
import { Link } from "gatsby";
import {
  Grid,
  Box,
  Container,
  Typography,
  Button,
  useTheme,
  Link as MuiLink,
  useMediaQuery,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { navigate } from "gatsby";

import { Logo } from "../../Logo";
import { Segment } from "../../Segment";

import { content } from "./Footer.content";

import { NavItem } from "./Footer.types";

import { setPage } from "../../../store/reducers/questionnaire.reducer";

export const Footer: React.FC = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleNavigate = (route: string) => {
    dispatch(setPage(0)); // Set the questionnaire to the first page
    navigate(route);
  };

  // Function to render navigation items
  const renderNavItems = (items: NavItem[]) => {
    return items.map((item) => (
      <Box key={item.id}>
        <Button
          onClick={() => handleNavigate(item.route)}
          sx={{ color: "black" }}
        >
          {item.title}
        </Button>
      </Box>
    ));
  };

  return (
    <Segment variant="pale">
      <Container>
        <Grid container py={4}>
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              justifyContent={isMobile ? `center` : `flex-start`}
              mb={2}
            >
              <Logo />
            </Box>
            <Grid container spacing={2} mb={2}>
              {/* Render footerNav[0] items */}

              <Grid item xs={12} md={6}>
                {renderNavItems(content.footerNav[0].items)}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Segment>
  );
};
