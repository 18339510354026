import { ThemeOptions, createTheme } from "@mui/material/styles";
import { headlineFont, bodyFont } from "./fonts";
import {
  primaryColor,
  primaryColorDark,
  primaryColorLight,
  secondaryColor,
  secondaryColorDark,
  secondaryColorLight,
} from "./colors";
// Define common typography and components configurations
const commonTypography = {
  fontFamily: bodyFont, // Default font for the body text
  h1: {
    fontFamily: headlineFont,
  },
  h2: {
    fontFamily: headlineFont,
  },
  h3: {
    fontFamily: headlineFont,
  },
  h4: {
    fontFamily: headlineFont,
  },
  h5: {
    fontFamily: headlineFont,
  },
  h6: {
    fontFamily: headlineFont,
  },
};

const commonComponents = {
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: 0,
        // Add empty objects for variants you want to override
        contained: {},
        outlined: {},
        text: {},
        color: `#fff`,
      },
    },
  },
};

// Create a base theme configuration
const baseTheme: ThemeOptions = {
  palette: {
    primary: {
      dark: primaryColorDark,
      main: primaryColor,
      light: primaryColorLight,
    },
    secondary: {
      dark: secondaryColorDark,
      main: secondaryColor,
      light: secondaryColorLight,
    },
  },
  typography: commonTypography,
  components: commonComponents,
};

// Extend the base theme for light mode
export const theme = createTheme({
  ...baseTheme,
  palette: {
    ...baseTheme.palette,
    mode: "light",
  },
});

// Extend the base theme for dark mode
export const darkTheme = createTheme({
  ...baseTheme,
  palette: {
    ...baseTheme.palette,
    mode: "dark",
  },
});
