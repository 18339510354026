import React, { ReactNode } from "react";
import { Box } from "@mui/system";

interface AlignVertCenterProps {
  height: number;
  children: ReactNode;
}

export const AlignVertCenter: React.FC<AlignVertCenterProps> = ({
  height,
  children,
}) => {
  return (
    <Box
      height={height}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      {children}
    </Box>
  );
};
