import { configureStore } from "@reduxjs/toolkit";

import userReducer from "./reducers/user.reducer";
import questionnaireReducer from "./reducers/questionnaire.reducer";

export const store = configureStore({
  reducer: {
    userReducer,
    questionnaireReducer,
  },
  middleware(getDefaultMiddleware) {
    return getDefaultMiddleware({
      serializableCheck: false,
    });
  },
});
