import { uid } from "uid";
import {
  QUESTIONNAIRE_BUY,
  QUESTIONNAIRE_RENT,
  HOME_TOURS,
  CONTACT_US,
  PROPERTY_MANAGERS,
  TERMS_AND_CONDITIONS,
} from "../../../constants/routes";

export const content = {
  footerNav: [
    {
      id: uid(),
      items: [
        {
          id: uid(),
          title: "Buy",
          route: QUESTIONNAIRE_BUY,
        },
        {
          id: uid(),
          title: "Rent",
          route: QUESTIONNAIRE_RENT,
        },
        {
          id: uid(),
          title: "Home Tours",
          route: HOME_TOURS,
        },
        {
          id: uid(),
          title: "Contact Us",
          route: CONTACT_US,
        },
        {
          id: uid(),
          title: "Terms & Conditions",
          route: TERMS_AND_CONDITIONS,
        },
      ],
    },
    {
      id: uid(),
      items: [
        {
          id: uid(),
          title: "Realtors",
          route: PROPERTY_MANAGERS,
        },
        {
          id: uid(),
          title: "Property Manager",
          route: PROPERTY_MANAGERS,
        },
        {
          id: uid(),
          title: "Request Tour",
          route: CONTACT_US,
        },
        {
          id: uid(),
          title: "Partnerships",
          route: CONTACT_US,
        },
      ],
    },
  ],
};
