export const buyQuestions = {
  monthlyBudget: {
    id: "monthly_budget",
    question: "What is your monthly budget for purchasing a property?",
    type: "single_selection",
    options: [
      { label: "$1,000-$2,000", value: "$1k-2k" },
      { label: "$2,000-$3,000", value: "$2k-3k" },
      { label: "$3,000-$4,000", value: "$3k-4k" },
      { label: "$4,000-$5,000", value: "$4k-5k" },
      { label: "$5,000+", value: "$5k+" },
    ],
  },
  locationType: {
    id: "location_type",
    question: "Do you prefer to live in a City, Country, or Suburb?",
    type: "multiple_selection",
    options: [
      { label: "City", value: "City" },
      { label: "Country", value: "Country" },
      { label: "Suburb", value: "Suburb" },
    ],
  },
  lifestyle: {
    id: "lifestyle",
    question: "Rate the following lifestyle questions?",
    type: "rating",
    options: {
      Walkability: 3,
      "Stores/Restaurants": 3,
      "Road Appeal": 3,
      "Public Schools": 3,
      "Parks/Nature": 3,
      "Near Lakes": 3,
      "Near Coast": 3,
      Livibility: 3,
      Commute: 3,
      "Community Vibrancy": 3,
    },
  },
  propertyType: {
    id: "property_type",
    question: "What type of property are you looking for?",
    type: "single_selection",
    options: [
      { label: "Condo", value: "Condo" },
      { label: "Single Family", value: "Single Family" },
      { label: "Townhouse", value: "Townhouse" },
      { label: "Multi-Family", value: "Multi-Family" },
    ],
  },
  bedrooms: {
    id: "bedrooms",
    question: "How many bedrooms?",
    type: "single_selection",
    options: [
      { label: "Any", value: "Any" },
      { label: "1", value: "1" },
      { label: "2", value: "2" },
      { label: "3", value: "3" },
      { label: "4+", value: "4+" },
    ],
  },
  bathrooms: {
    id: "bathrooms",
    question: "How many bathrooms?",
    type: "single_selection",
    options: [
      { label: "Any", value: "Any" },
      { label: "1", value: "1" },
      { label: "1+", value: "1+" },
      { label: "2", value: "2" },
      { label: "2+", value: "2+" },
      { label: "3", value: "3" },
      { label: "3+", value: "3+" },
    ],
  },
  propertyFeatures: {
    id: "property_features",
    question: "What amenities are important to you?",
    type: "multiple_selection",
    options: [
      { label: "New Construction", value: "New Construction" },
      { label: "Pool", value: "Pool" },
      { label: "Waterfront", value: "Waterfront" },
      { label: "Central A/C", value: "Central A/C" },
      { label: "No HOA", value: "No HOA" },
      { label: "Newly Renovated", value: "Newly Renovated" },
      { label: "Yard", value: "Yard" },
      { label: "Luxury", value: "Luxury" },
    ],
  },
};

export const rentQuestions = {
  monthlyBudget: {
    id: "monthly_budget",
    question: "What is your monthly budget for purchasing a property?",
    type: "single_selection",
    options: [
      { label: "$1,000-$2,000", value: "$1-2k" },
      { label: "$2,000-$3,000", value: "$2-3k" },
      { label: "$3,000-$4,000", value: "$3-4k" },
      { label: "$4,000-$5,000", value: "$4-5k" },
      { label: "$5,000+", value: "$5k+" },
    ],
  },
  locationType: {
    id: "location_type",
    question: "Do you prefer to live in a City, Country, or Suburb?",
    type: "multiple_selection",
    options: [
      { label: "City", value: "City" },
      { label: "Country", value: "Country" },
      { label: "Suburb", value: "Suburb" },
    ],
  },
  lifestyle: {
    id: "lifestyle",
    question: "Rate the following lifestyle questions?",
    type: "rating",
    options: {
      Walkability: 3,
      "Stores/Restaurants": 3,
      "Parks/Nature": 3,
      "Public Schools": 3,
      "Near Coast": 3,
      "Near Lakes": 3,
      "Road Appeal": 3,
      Commute: 3,
      "Community Vibrancy": 3,
      Livibility: 3,
    },
  },
  propertyType: {
    id: "property_type",
    question: "What type of property are you looking for?",
    type: "single_selection",
    options: [
      { label: "House", value: "House" },
      { label: "Townhouse", value: "Townhouse" },
      { label: "Apartment", value: "Apartment" },
      { label: "Condo", value: "Condo" },
    ],
  },
  bedrooms: {
    id: "bedrooms",
    question: "How many bedrooms?",
    type: "single_selection",
    options: [
      { label: "Any", value: "Any" },
      { label: "1", value: "1" },
      { label: "2", value: "2" },
      { label: "3", value: "3" },
      { label: "4+", value: "4+" },
    ],
  },
  bathrooms: {
    id: "bathrooms",
    question: "How many bathrooms?",
    type: "single_selection",
    options: [
      { label: "Any", value: "Any" },
      { label: "1", value: "1" },
      { label: "1+", value: "1+" },
      { label: "2", value: "2" },
      { label: "2+", value: "2+" },
      { label: "3", value: "3" },
      { label: "3+", value: "3+" },
    ],
  },
  propertyFeatures: {
    id: "property_features",
    question: "What amenities are important to you?",
    type: "multiple_selection",
    options: [
      { label: "New Construction", value: "New Construction" },
      { label: "Newly Renovated", value: "Newly Renovated" },
      { label: "Pool", value: "Pool" },
      { label: "Yard", value: "Yard" },
      { label: "Luxury", value: "Luxury" },
      { label: "Waterfront", value: "Waterfront" },
      { label: "Garage", value: "Garage" },
      { label: "Pet Friendly", value: "Pet Friendly" },
      { label: "Parking", value: "Parking" },
      { label: "Concierge", value: "Concierge" },
      { label: "In-Unit Laundry", value: "In-Unit Laundry" },
    ],
  },
};
