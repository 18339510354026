import React from "react";
import { Box, useTheme, useMediaQuery } from "@mui/material";

import { paleColor, tealColor, peachColor } from "../../config/theme/colors";
interface SegmentProps {
  variant?:
    | "white"
    | "black"
    | "grey"
    | "greyDark"
    | "gradient"
    | "gradientReverse"
    | "primaryLight"
    | "primary"
    | "primaryDark"
    | "secondaryLight"
    | "secondary"
    | "secondaryDark"
    | "tertiary"
    | "teal"
    | "pale"
    | "transparent"; // Include "transparent"
  children: React.ReactNode;
  collapse?: boolean;
  elevation?: number;
  collapseX?: boolean;
  collapseY?: boolean;
}

export const Segment: React.FC<SegmentProps> = ({
  variant = "transparent", // Default value
  collapse = false, // Default collapse to false
  collapseX = false,
  collapseY = false,
  children,
  elevation = 0,
}) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  // Define the background color for each variant, including "transparent"
  const backgroundColors: Record<string, string> = {
    white: theme.palette.common.white,
    primaryLight: theme.palette.primary.light,
    primary: theme.palette.primary.main,
    primaryDark: theme.palette.primary.dark,
    secondaryLight: theme.palette.secondary.light,
    secondary: theme.palette.secondary.main,
    secondaryDark: theme.palette.secondary.dark,
    gradient: `linear-gradient(180deg, rgba(0,0,0) 0%, transparent 100%)`,
    gradientReverse: `linear-gradient(0deg, rgba(0,0,0) 0%, transparent 100%)`,
    transparent: "transparent", // Add transparent option
    grey: "#f2f7fa",
    greyDark: "#e1ecf2",
    black: theme.palette.common.black,
    tertiary: peachColor,
    pale: paleColor,
    teal: tealColor,
  };

  // Ensure variant is a string and use it to get the background color
  const bgColor = backgroundColors[variant as string];

  // Define padding based on collapse props
  const padding = {
    ...(collapse ? { p: 0 } : {}), // Apply zero padding if collapse is true
    ...(collapseX ? { pl: 0, pr: 0 } : {}), // Apply zero left/right padding if collapseX is true
    ...(collapseY ? { pt: 0, pb: 0 } : {}), // Apply zero top/bottom padding if collapseY is true
    ...(isSmall ? { p: 2 } : { p: 10 }), // Adjust padding for small screens
  };

  return (
    <Box sx={{ background: bgColor, ...padding }} boxShadow={elevation}>
      {children}
    </Box>
  );
};
