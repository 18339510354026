export const primaryColor: string = "#c66d55";
export const primaryColorDark: string = "#e18b26";
export const primaryColorLight: string = "#f0cfa0";

export const secondaryColor: string = "#304e3c";
export const secondaryColorDark: string = "#322e24";
export const secondaryColorLight: string = "#789e9e";

export const paleColor: string = "#fae9d1";
export const tealColor: string = "#809d9e";
export const peachColor: string = `#e9d0a5`;
