import * as React from "react";
import { Amplify } from "aws-amplify";
import awsmobile from "../../src/aws-exports";
import { UiShell } from "../../src/components/UiShell";
import "../../src/fonts/font.css";
Amplify.configure({ ...awsmobile });

export default function TopLayout(props) {
  return <UiShell>{props.children}</UiShell>;
}
