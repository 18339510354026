// questionnaireSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { buyQuestions, rentQuestions } from "./questions";
import { buyAnswers, rentAnswers } from "./answers";

// Define types for options and questions
interface Option {
  label: string;
  value: string;
}

interface Question {
  id: string;
  question: string;
  type: string;
  options: Option[] | Record<string, number>;
}

// Define the initial state
interface QuestionnaireState {
  buyQuestions: Record<string, Question>;
  rentQuestions: Record<string, Question>;
  buyAnswers: Record<string, any>;
  rentAnswers: Record<string, any>;
  currentPage: number;
  buyResults: any;
  rentResults: any;
  flowType: string;
}

const initialState: QuestionnaireState = {
  buyQuestions: buyQuestions,
  rentQuestions: rentQuestions,
  buyAnswers: buyAnswers,
  rentAnswers: rentAnswers,
  currentPage: 0,
  buyResults: null,
  rentResults: null,
  flowType: "",
};

export const questionnaireSlice = createSlice({
  name: "questionnaire",
  initialState,
  reducers: {
    nextPage: (state) => {
      state.currentPage += 1;
    },
    prevPage: (state) => {
      state.currentPage -= 1;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    updateAnswer: (
      state,
      action: PayloadAction<{
        id: string;
        answer: any;
        type: "buyQuestions" | "rentQuestions";
      }>
    ) => {
      const { id, answer, type } = action.payload;
      if (type === "buyQuestions") {
        state.buyAnswers[id] = answer;
      } else {
        state.rentAnswers[id] = answer;
      }
    },
    setBuyQuestions: (state, action: PayloadAction<any>) => {
      state.buyQuestions = action.payload;
    },
    setRentQuestions: (state, action: PayloadAction<any>) => {
      state.rentQuestions = action.payload;
    },
    setBuyResults: (state, action: PayloadAction<any>) => {
      state.buyResults = {
        ...state.buyResults, // Spread the existing results
        ...action.payload, // Merge new results, assuming it's an object
      };
    },

    setRentResults: (state, action: PayloadAction<any>) => {
      state.rentResults = {
        ...state.rentResults, // Spread the existing results
        ...action.payload, // Merge new results
      };
    },
    resetBuyQuestionnaire: (state) => {
      state.currentPage = 0;
      state.buyAnswers = {};
      state.buyResults = null;
    },
    resetRentQuestionnaire: (state) => {
      state.currentPage = 0;
      state.rentAnswers = {};
      state.rentResults = null;
    },
    setFlowType: (state, action: PayloadAction<string>) => {
      state.flowType = action.payload;
    },
  },
});

export const {
  nextPage,
  prevPage,
  setPage,
  updateAnswer,
  setBuyResults,
  setRentResults,
  setBuyQuestions,
  setRentQuestions,
  resetBuyQuestionnaire,
  resetRentQuestionnaire,
  setFlowType,
} = questionnaireSlice.actions;

export default questionnaireSlice.reducer;
